
























import { Component, Vue } from 'vue-property-decorator'
import LayoutCompactFooterOnly from '@/layouts/LayoutCompactFooterOnly.vue'
import Logo from '@/components/Logo.vue'
import LogoService from '@/services/LogoService'


@Component({
  components: {
    LayoutCompactFooterOnly,
    Logo
  }
})
export default class MemberNoSelectStore extends Vue {
  public image: any = ''

  async created() {
    await LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })
  }

  public backToMemberInfo() {
    this.$router.push({ name: 'membership-information' })
  }
  public gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
